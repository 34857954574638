<template>
  <div>
    <v-row>
      <v-col cols="2"
        ><v-select label="Jaar" :items="years" v-model="year"></v-select
      ></v-col>
      <v-col cols="2"
        ><v-select
          label="Kwartaal"
          :items="quarters"
          v-model="quarter"
        ></v-select
      ></v-col>
      <v-col class="align-self-center"
        ><v-btn color="primary" @click="fetchData">Verzenden</v-btn></v-col
      >
      <v-col class="align-self-center"
        ><v-btn color="primary" @click="submitData">Opslaan</v-btn></v-col
      >
    </v-row>
    <v-card class="pa-2">
      <fieldset>
        <legend>Prestaties binnenland</legend>
        <table>
          <tr>
            <th>Leveringen/diensten belast met hoog tarief</th>
            <td class="td-values">
              {{ toCurrencyRounded(VATBase(VATHighTotal, VATHighPercentage)) }}
            </td>
            <td class="td-values">{{ toCurrencyRounded(VATHighTotal) }}</td>
          </tr>
          <tr>
            <th>Leveringen/diensten belast met laag tarief</th>
            <td class="td-values">
              {{ toCurrencyRounded(VATBase(VATLowTotal, VATLowPercentage)) }}
            </td>
            <td class="td-values">{{ toCurrencyRounded(VATLowTotal) }}</td>
          </tr>
          <tr>
            <th>Leveringen/diensten overige tarieven, behalve 0%</th>
            <td class="td-values">
              {{ toCurrencyRounded(VATBase(VATOtherTotal, 0)) }}
            </td>
            <td class="td-values">{{ toCurrencyRounded(VATOtherTotal) }}</td>
          </tr>
          <tr>
            <th>Privégebruik</th>
            <td class="td-values">{{ toCurrencyRounded(privateUse) }}</td>
            <td class="td-values">{{ toCurrencyRounded(privateUse) }}</td>
          </tr>
          <tr>
            <th>Leveringen/diensten belast met 0% of niet bij u belast</th>
            <td class="td-values">
              {{ toCurrencyRounded(VATZeroTotal)}}
            </td>
            <td class="td-values">{{ toCurrencyRounded(VATZeroTotal) }}</td>
          </tr>
        </table>
      </fieldset>
      <fieldset>
        <legend>Verleggingsregelingen binnenland</legend>
        <table>
          <tr>
            <th>Heffing verlegd naar u</th>
            <td class="td-values">{{ toCurrencyRounded(VATReverseCharge) }}</td>
            <td class="td-values">{{ toCurrencyRounded(VATReverseCharge) }}</td>
          </tr>
        </table>
      </fieldset>
      <fieldset>
        <legend>Prestaties naar/in het buitenland</legend>
        <table>
          <tr>
            <th>Leveringen/diensten naar landen buiten de EU</th>
            <td class="td-values">{{ toCurrencyRounded(exportOutsideEU) }}</td>
            <td class="td-values"></td>
          </tr>
          <tr>
            <th>Leveringen/diensten naar landen binnen de EU</th>
            <td class="td-values">{{ toCurrencyRounded(exportEU) }}</td>
            <td class="td-values"></td>
          </tr>
          <!-- <tr>
            <th>Installatie/afstandsverkopen binnen de EU</th>
            <td class="td-values">{{ todo }}</td>
            <td class="td-values">{{ todo }}</td>
          </tr> -->
        </table>
      </fieldset>
      <fieldset>
        <legend>Prestaties uit het buitenland aan u verricht</legend>
        <table>
          <tr>
            <th>Leveringen/diensten uit landen buiten de EU</th>
            <td class="td-input"><input type="number" v-model.number="VATReverseChargeOutsideEU"></td>
            <td class="td-input"><input type="number" v-model.number="VATReverseChargeOutsideEUBase"></td>
          </tr>
          <tr>
            <th>Leveringen/diensten uit landen binnen de EU</th>
           <td class="td-input"><input type="number" v-model.number="VATReverseChargeEU"></td>
            <td class="td-input"><input type="number" v-model.number="VATReverseChargeEUBase"></td>
          </tr>
        </table>
      </fieldset>
      <fieldset>
        <legend>Voorbelasting, kleineondernemingsregeling en eindtotaal</legend>
        <table>
          <tr>
            <th>Verschuldigde omzetbelasting</th>
            <td style="width:200px;"></td>
            <td class="td-values">{{ toCurrencyRounded(Math.round(VATDebit)) }}</td>
          </tr>
          <tr>
            <th>Voorbelasting</th>
            <td></td>
            <td class="td-input"><input type="number" v-model.number="inputTax"></td>
          </tr>
          <tr>
            <th>Subtotaal</th>
            <td></td>
            <td class="td-values">{{ toCurrencyRounded(Math.round(VATSubTotal)) }}</td>
          </tr>
          <tr>
            <th>Vermindering kleineondernemingsregeling</th>
            <td></td>
            <td class="td-input"><input type="number" v-model.number="SBSDeduction"></td>
          </tr>
          <tr>
            <th>Totaal te betalen/terug te vragen</th>
            <td></td>
            <td class="td-values">{{ toCurrencyRounded(Math.round(VATNetTotal)) }}</td>
          </tr>
        </table>
      </fieldset>
    </v-card>
  </div>
</template>

<script>
import { fetchGET, toCurrencyRounded } from "../js/functions"
export default {
  name: "VATDeclaration",
  data() {
    return {
      todo: "",
      fromDate: "",
      toDate: "",
      year: 0,
      quarter: 0,
      month: 0,
      week: 0,
      day: 0,
      invoiceNetTotal: 0,
      invoiceGrossTotal: 0,
      VAT: [
        { _id: 21, total: 0, name: "Hoog" },
        { _id: 9, total: 0, name: "Laag" }
      ],
      VATHighTotal: 0,
      VATHighPercentage: 21,
      VATLowTotal: 0,
      VATLowPercentage: 9,
      VATZeroTotal: 0,
      VATOtherTotal: 0,
      VATReverseCharge: 0,
      VATReverseChargeOutsideEU: 0,
      VATReverseChargeOutsideEUBase: 0,
      VATReverseChargeEU: 0,
      VATReverseChargeEUBase: 0,
      privateUse: 0,
      inputTax: 0,
      SBSDeduction: 0,
      years: [2019, 2020, 2021,2022,2023,2024,2025],
      quarters: [1, 2, 3, 4],
      months: [
        { text: "Januari", value: 1 },
        { text: "Februari", value: 2 },
        { text: "Maart", value: 3 },
        { text: "April", value: 4 },
        { text: "Mei", value: 5 },
        { text: "Juni", value: 6 },
        { text: "Juli", value: 7 },
        { text: "Augustus", value: 8 },
        { text: "September", value: 9 },
        { text: "Oktober", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 }
      ],
      exportEU: 0,
      exportOutsideEU: 0
    }
  },
  methods: {
    toCurrencyRounded(val) {
      return toCurrencyRounded(val)
    },
    VATBase(val, vat) {
      if (val == 0 && vat == 0) return val
      return (parseFloat(val) / parseInt(vat)) * 100
    },
    async fetchData() {
      try {
        let obj = {}
        if (this.fromDate && this.toDate) {
          obj.fromDate = this.fromDate
          obj.toDate = this.toDate
        }
        if (this.year) obj.year = parseInt(this.year)
        if (this.year && this.quarter) obj.quarter = parseInt(this.quarter)
        if (this.year && this.month) obj.month = parseInt(this.month)
        if (this.year && this.week) obj.week = parseInt(this.week)
        if (this.year && this.month && this.day) obj.day = parseInt(this.day)
        let res = await fetchGET("revenue", obj)
        console.log(res
        )
        let VAT = res[1].value[0] ? res[1].value: []
        let VATHighTotal = VAT.find(vat=>vat.name=="Hoog")
        this.VATHighTotal = VATHighTotal?VATHighTotal.total : 0
        let VATLowTotal = VAT.find(vat=>vat.name=="Laag")
        this.VATLowTotal = VATLowTotal? VATLowTotal.total : 0
        this.VATOtherTotal = 0
        this.VATZeroTotal = 0
        this.exportEU = res[2].value[0] ? res[2].value[0].netTotal : 0
        this.exportOutsideEU = res[3].value[0] ? res[3].value[0].netTotal: 0
        this.invoiceNetTotal = res[0].value[0] ? res[0].value[0].netTotal : 0
        this.invoiceGrossTotal = res[0].value[0]
          ? res[0].value[0].grossTotal
          : 0
        ;(this.VAT = res[1].value.length
          ? res[1].value.filter(v => v._id != 0).sort((a, b) => b._id - a._id)
          : [
              { _id: 9, total: 0, name: "Laag" },
              { _id: 21, total: 0, name: "Hoog" }
            ]),
          (this.exportEU = res[2].value[0] ? res[2].value[0].netTotal : 0)
        this.exportOutsideEU = res[3].value[0] ? res[3].value[0].netTotal : 0
      } catch (e) {
        console.error(e)
      }
    },
    submitData() {}
  },
  computed: {
    VATDebit(){
return this.VATHighTotal + this.VATLowTotal + this.VATOtherTotal + this.VATZeroTotal + this.privateUse + this.VATReverseCharge + this.VATReverseChargeOutsideEUBase  + this.VATReverseChargeEUBase
    },
    VATSubTotal(){
      return this.VATDebit - this.inputTax
    },
    VATNetTotal() {
      return this.VATSubTotal - this.SBSDeduction
    }
  },
}
</script>

<style scoped>
th {
  width: 400px;
  text-align: left;
}

.td-values {
  background: lightgray;
  border: 1px solid grey;
  padding: 2px;
  width: 200px;
}
.td-input {
  background: yellow;
  border: 1px solid grey;
  padding: 2px;
  width: 200px;
}
</style>